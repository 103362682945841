import { PaymentInfo, SET_EDITED, SET_PAYMENT_INFO, SET_TRAVELLER_VALID } from 'actions/Checkout'

export interface CheckoutState {
  travellersValid: boolean[]
  travellersEdited: boolean[]
  paymentInfo: PaymentInfo
  termsConditions?: string
}

export const INITIAL_CHECKOUT = {
  travellersValid: [],
  travellersEdited: [],
  paymentInfo: {
    paymentAmount: 'deposit',
    paymentMethod: 'ideal',
    termsConditions: '',
  },
}

const CheckoutReducer = (state: CheckoutState = INITIAL_CHECKOUT, action: any): any => {
  switch (action.type) {
    case SET_TRAVELLER_VALID: {
      const { index, valid } = action
      const travellersValid = [...state.travellersValid]
      travellersValid[index] = valid
      return {
        ...state,
        travellersValid,
      }
    }
    case SET_EDITED: {
      const { index, edited } = action
      const travellersEdited = [...state.travellersEdited]
      travellersEdited[index] = edited
      return {
        ...state,
        travellersEdited,
      }
    }
    case SET_PAYMENT_INFO: {
      const { newPaymentInfo } = action
      return {
        ...state,
        paymentInfo: { ...state.paymentInfo, ...newPaymentInfo },
      }
    }
    default: {
      return state
    }
  }
}

export default CheckoutReducer
