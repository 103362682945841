/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Row, Col } from 'react-grid-system'
import { Field, Form as FinalForm } from 'react-final-form'

import currency from 'helpers/currency'
import moment from 'moment'

import Box from 'styles/Box'
import { H3 } from 'styles/Heading'
import Text from 'styles/Text'
import { RadioField, SelectField, CheckboxField } from 'styles/Form'
import { QuoteDataType } from 'types'
import { CheckoutCard } from 'components/Checkout/CheckoutCard/CheckoutCard'
import styled from 'styled-components'
import useCompany from 'hooks/useCompany'

import idealLogo from 'assets/images/ideal-logo.png'
import bancontactLogo from 'assets/images/bancontact-logo.png'
import americanExpressLogo from 'assets/images/american-express-logo.png'
import visaLogo from 'assets/images/visa-logo.png'
import mastercardLogo from 'assets/images/mastercard-logo.png'
import { isRequired } from 'helpers/validators'
import { setPaymentInfo } from 'actions/Checkout'
import { ApplicationState } from 'reducers'
import { CREDITCARD_MULTIPLIER, CREDITCARD_MULTIPLIER_AMEX, CREDITCARD_ISSUERS } from 'constants/constants'

import { createNotification } from 'actions/Notifications'

const PaymentMethodRow = styled(Row)`
  border-top: 1px solid #e5e5e5;
  padding: 8px 16px !important;
`

const PaymentFormContainer = styled.div`
  padding: 0px;
  input[type='radio'] {
    width: 20px;
    height: 20px;
    accent-color: ${({ theme }) => theme.color.neutral.dark};
    border: 2px solid ${({ theme }) => theme.color.neutral.dark};
  }
  line-height: 1.75rem;
`

const PaymentFormCol = styled(Col)`
  padding: 0px !important;
`

const PaymentFormRow = styled(Row)`
  padding: 8px 16px !important;
`
const BulletPoint = styled.li`
  list-style-type: disc;
  margin: 5px 0;
`

const BulletList = styled.ul`
  padding-left: 1.5rem;
`

export type CheckoutPaymentFormValues = {
  paymentMethod: 'ideal' | 'creditcard' | 'bancontact'
  paymentAmount: 'deposit' | 'total' | 'option'
  termsConditions?: string
  creditcardIssuer?: string
}

const creditcardIssuers = CREDITCARD_ISSUERS

const CheckoutPayment: React.FC = () => {
  const dispatch = useDispatch()
  const { itinerary, paymentMethods }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const ideal = paymentMethods?.find(({ id }) => id === 'ideal')
  const creditcard = paymentMethods?.find(({ id }) => id === 'creditcard')
  const bancontact = paymentMethods?.find(({ id }) => id === 'bancontact')
  const formRef: React.MutableRefObject<any> = useRef()
  const { short, TACLink } = useCompany()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const status = params.get('status')

  useEffect(() => {
    if (!formRef.current || !paymentMethods || !paymentMethods.length) return
    dispatch(
      setPaymentInfo({
        creditcardIssuer: formRef?.current?.getState()?.values?.creditcardIssuer,
        paymentAmount: formRef?.current?.getState()?.values?.paymentAmount,
        paymentMethod: formRef?.current?.getState()?.values?.paymentMethod ?? paymentMethods[0],
        termsConditions: '',
        creditcardMaximum: Number(creditcard?.maximumAmount?.value),
      })
    )

    if (status === 'failed') {
      dispatch(
        createNotification({
          content:
            'De betaling kon niet verwerkt worden. Probeer het opnieuw of of neem contact op met onze klantenservice om u verder te helpen.',
          status: 'danger',
        })
      )
    }

    if (status === 'option') {
      dispatch(
        createNotification({
          content:
            'Uw betaling is geslaagd, de offerte staat tot xx-xx-xxxx voor u vast. Er is een bedrag van 50,00 in mindering gebracht op de totale reissom.',
          status: 'success',
        })
      )
    }
  }, [paymentMethods, dispatch])

  function toIdeal() {
    formRef.current.change('paymentMethod', 'ideal')
    const idealInput = document.querySelector('input#ideal') as HTMLInputElement
    const creditcardInput = document.querySelector('input#creditcard') as HTMLInputElement

    if (idealInput && creditcardInput) {
      creditcardInput.checked = false
      idealInput.checked = true
    }
  }

  const onChange = (value: string, name: string) => {
    if (!itinerary || !formRef.current) return value

    const isDeposit = formRef?.current?.getState()?.values?.paymentAmount !== 'deposit'
    // const amount = isDeposit ? itinerary.deposit : itinerary.totalCosts
    const amount = isDeposit
      ? itinerary.optionBookingPaid
        ? itinerary.deposit - 50
        : itinerary.deposit
      : itinerary.optionBookingPaid
      ? itinerary.totalCosts - 50
      : itinerary.totalCosts

    const paymentMethod = formRef?.current?.getState()?.values?.paymentMethod

    // If option changes from credit card and amount is higher than max amount, change to default
    if (
      name === 'paymentAmount' &&
      amount > Number(creditcard?.maximumAmount?.value) / CREDITCARD_MULTIPLIER &&
      paymentMethod === 'creditcard'
    ) {
      toIdeal()
    }

    if (name === 'paymentMethod' && value !== 'creditcard') {
      formRef.current.change('creditcardIssuer', undefined)
    }
    dispatch(
      setPaymentInfo({
        creditcardIssuer: formRef?.current?.getState()?.values?.creditcardIssuer,
        paymentAmount: formRef?.current?.getState()?.values?.paymentAmount,
        paymentMethod: formRef?.current?.getState()?.values?.paymentMethod,
        ...{ [name]: value },
      })
    )
    return value
  }
  return (
    <PaymentFormContainer>
      {itinerary?.status === 'open' && (
        <FinalForm
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSubmit={() => {}}
          render={({ form, values: { paymentAmount } }) => {
            formRef.current = form
            const isDeposit = paymentAmount === 'deposit'

            const totalCostsAmount = itinerary.optionBookingPaid ? itinerary.totalCosts - 50 : itinerary.totalCosts
            const depositAmount = itinerary.optionBookingPaid ? itinerary.deposit - 50 : itinerary.deposit
            const amount = isDeposit ? depositAmount : totalCostsAmount
            const paymentMethod = formRef?.current?.getState()?.values?.paymentMethod
            const disableCreditcard = amount > Number(creditcard?.maximumAmount.value) / CREDITCARD_MULTIPLIER

            let multiplier = 1
            if (
              formRef?.current?.getState()?.values?.creditcardIssuer === 'visa' ||
              formRef?.current?.getState()?.values?.creditcardIssuer === 'mastercard'
            ) {
              multiplier = CREDITCARD_MULTIPLIER
            } else if (formRef?.current?.getState()?.values?.creditcardIssuer === 'amex') {
              multiplier = CREDITCARD_MULTIPLIER_AMEX
            }

            if (paymentMethod !== 'creditcard') {
              multiplier = 1
            }

            return (
              <form>
                <CheckoutCard>
                  <Box>
                    <H3>Bedrag kiezen</H3>
                    <PaymentFormRow>
                      <PaymentFormCol xs={1}>
                        <Field
                          name="paymentAmount"
                          component={RadioField}
                          type="radio"
                          id="paymentAmount-total"
                          value="total"
                          defaultValue="total"
                          parse={onChange}
                          defaultChecked={!itinerary.deposit}
                        />
                      </PaymentFormCol>
                      <PaymentFormCol xs={11}>
                        <label htmlFor="paymentAmount-total">
                          {'Totale reissom '}
                          <strong>{currency.format(totalCostsAmount)}</strong>
                        </label>
                      </PaymentFormCol>
                    </PaymentFormRow>
                    {itinerary.deposit > 0 && itinerary.deposit !== itinerary.totalCosts && (
                      <PaymentFormRow>
                        <PaymentFormCol xs={1}>
                          <Field
                            name="paymentAmount"
                            component={RadioField}
                            type="radio"
                            id="paymentAmount-deposit"
                            value="deposit"
                            defaultValue="deposit"
                            parse={onChange}
                            defaultChecked={!!itinerary.deposit}
                          />
                        </PaymentFormCol>
                        <PaymentFormCol xs={11}>
                          <label htmlFor="paymentAmount-deposit">
                            {'Aanbetaling '}
                            <strong>{currency.format(depositAmount)}</strong>
                          </label>
                        </PaymentFormCol>
                      </PaymentFormRow>
                    )}

                    {itinerary.optionBooking && (
                      <>
                        <PaymentMethodRow>
                          <PaymentFormCol xs={12}>
                            <H3 style={{ marginTop: '10px' }}>Optie kiezen</H3>
                          </PaymentFormCol>
                        </PaymentMethodRow>

                        <PaymentFormRow>
                          <PaymentFormCol xs={1}>
                            <Field
                              name="paymentAmount"
                              component={RadioField}
                              type="radio"
                              id="paymentAmount-option"
                              value="option"
                              parse={onChange}
                            />
                          </PaymentFormCol>
                          <PaymentFormCol xs={11}>
                            <label htmlFor="paymentAmount-deposit">
                              Optieboeking <strong>{currency.format(50.0)}</strong>
                            </label>
                          </PaymentFormCol>
                        </PaymentFormRow>

                        <PaymentFormRow>
                          <PaymentFormCol xs={12}>
                            <BulletList>
                              <BulletPoint>
                                Hiermee wordt uw boeking vastgehouden tot{' '}
                                {moment(itinerary?.expireDate).add(2, 'days').format('DD MMMM YYYY')}
                              </BulletPoint>
                              <BulletPoint>
                                Bevestigt u de reis binnen deze periode, dan wordt het bedrag van de optieboeking in
                                mindering gebracht op de totale reissom.
                              </BulletPoint>
                            </BulletList>
                          </PaymentFormCol>
                        </PaymentFormRow>

                        <PaymentFormRow>
                          <PaymentFormCol xs={12} style={{ marginTop: '32px' }}>
                            <H3>Kies een betaalmethode</H3>
                            <Text size="sm" color="neutral">
                              Alle transacties zijn beveiligd
                            </Text>
                          </PaymentFormCol>
                        </PaymentFormRow>
                      </>
                    )}

                    {ideal && (
                      <PaymentMethodRow>
                        <PaymentFormCol xs={1}>
                          <Field
                            name="paymentMethod"
                            component={RadioField}
                            type="radio"
                            defaultChecked
                            value={ideal.id}
                            id={ideal.id}
                            defaultValue={ideal.id}
                            parse={onChange}
                          />
                        </PaymentFormCol>
                        <PaymentFormCol xs={11}>
                          <Row>
                            <Col>
                              <label htmlFor="ideal">iDEAL</label>
                            </Col>
                            <Col style={{ justifyContent: 'right', display: 'flex' }}>
                              <img alt="" height="32px" src={idealLogo} />
                            </Col>
                          </Row>
                        </PaymentFormCol>
                      </PaymentMethodRow>
                    )}

                    {bancontact && (
                      <PaymentMethodRow>
                        <PaymentFormCol xs={1}>
                          <Field
                            name="paymentMethod"
                            component={RadioField}
                            type="radio"
                            value="bancontact"
                            id="bancontact"
                            parse={onChange}
                          />
                        </PaymentFormCol>
                        <PaymentFormCol xs={11}>
                          <Row>
                            <Col>
                              <label htmlFor="bancontact">Bancontact</label>
                            </Col>
                            <Col style={{ justifyContent: 'right', display: 'flex' }}>
                              <img alt="" height="34px" src={bancontactLogo} />
                            </Col>
                          </Row>
                        </PaymentFormCol>
                      </PaymentMethodRow>
                    )}

                    {creditcard && (
                      <PaymentMethodRow>
                        <PaymentFormCol xs={1}>
                          <Field
                            name="paymentMethod"
                            component={RadioField}
                            type="radio"
                            value={creditcard.id}
                            id={creditcard.id}
                            parse={onChange}
                            disabled={disableCreditcard}
                          />
                        </PaymentFormCol>

                        <PaymentFormCol xs={11}>
                          <Row>
                            <Col>
                              <label htmlFor="creditcard">Creditcard</label>
                            </Col>
                            <Col style={{ justifyContent: 'right', display: 'flex' }}>
                              <img alt="" height="24px" src={mastercardLogo} />
                              <img alt="" height="24px" src={americanExpressLogo} />
                              <img alt="" height="24px" src={visaLogo} />
                            </Col>
                          </Row>

                          <PaymentFormCol xs={1}></PaymentFormCol>
                          {form.getState().values.paymentMethod === 'creditcard' && (
                            <>
                              <PaymentFormCol xs={12} md={11}>
                                <Field
                                  name="creditcardIssuer"
                                  style={{ width: '450px', maxWidth: '100%' }}
                                  component={SelectField}
                                  isRequired
                                  defaultValue={creditcardIssuers ? creditcardIssuers[0].id : ''}
                                  validate={isRequired}
                                  noLabelMargin
                                  parse={onChange}
                                  label={
                                    <div
                                      style={{ textTransform: 'none', color: '#606060', fontSize: '12px', margin: 0 }}
                                    >
                                      Selecteer type
                                    </div>
                                  }
                                >
                                  {creditcardIssuers?.map(({ name, id }, index) => {
                                    return (
                                      <option key={id} value={id}>
                                        {name}
                                      </option>
                                    )
                                  })}
                                </Field>
                              </PaymentFormCol>
                              <Row>
                                <Col>
                                  {paymentMethod === 'creditcard' && amount * multiplier > 10000 && (
                                    <Text size="sm" color="danger">
                                      De limiet voor een betaling met creditcard is bereikt. Neem contact op met uw
                                      reisadviseur indien u toch van deze optie gebruik wilt maken.
                                    </Text>
                                  )}
                                  <Text size="sm" color="neutral">
                                    Let op: u betaalt +
                                    {
                                      creditcardIssuers.find(
                                        (issuer) => issuer.id === form.getState().values.creditcardIssuer
                                      )?.additionalServiceCharge
                                    }
                                    % transactiekosten. Deze kosten worden door de creditcardmaatschappij in rekening
                                    gebracht. Wilt u geen toeslag betalen? Selecteer dan een andere betaalwijze.
                                  </Text>
                                </Col>
                              </Row>
                            </>
                          )}
                          <>
                            <PaymentFormCol style={{ marginTop: '8px' }}>
                              {paymentMethod !== 'creditcard' && disableCreditcard && (
                                <Text size="sm" color="danger">
                                  De limiet voor een betaling met creditcard is bereikt. Neem contact op met uw
                                  reisadviseur indien u toch van deze optie gebruik wilt maken.
                                </Text>
                              )}
                            </PaymentFormCol>
                          </>
                        </PaymentFormCol>
                      </PaymentMethodRow>
                    )}

                    <PaymentMethodRow></PaymentMethodRow>

                    {!itinerary.paid ? (
                      <>
                        <PaymentFormRow>
                          <PaymentFormCol xs={12} style={{ marginTop: '32px' }}>
                            <H3>
                              Te betalen{' '}
                              {isDeposit
                                ? currency.format(depositAmount * multiplier)
                                : paymentAmount === 'option'
                                ? currency.format(50.0)
                                : currency.format(totalCostsAmount * multiplier)}
                            </H3>
                            <Text size="sm" color="neutral">
                              Zodra uw betaling is afgerond, sturen we u een e-mail met uw bevestiging.
                            </Text>
                          </PaymentFormCol>
                        </PaymentFormRow>

                        <PaymentFormRow>
                          <PaymentFormCol xs={1}>
                            <Field
                              name="termsConditions"
                              component={CheckboxField}
                              type="checkbox"
                              id="termsConditions"
                              value="checked"
                              parse={onChange}
                            />
                          </PaymentFormCol>
                          <PaymentFormCol xs={11}>
                            {short === 'mor' || short === 'slr' ? (
                              <Text size="sm" color="neutral">
                                Ik heb gelezen en ga akkoord met de{' '}
                                <a
                                  href={TACLink}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ color: '#606060', textDecoration: 'underline' }}
                                >
                                  Algemene voorwaarden
                                </a>{' '}
                                en de{' '}
                                <a
                                  href="https://www.anvr.nl/reizigersvoorwaarden.pdf"
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ color: '#606060', textDecoration: 'underline' }}
                                >
                                  ANVR-Reizigersvoorwaarden.
                                </a>
                              </Text>
                            ) : (
                              <Text size="sm" color="neutral">
                                Ik heb gelezen en ga akkoord met de{' '}
                                <a
                                  href={TACLink}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ color: '#606060', textDecoration: 'underline' }}
                                >
                                  Algemene voorwaarden.
                                </a>
                              </Text>
                            )}
                          </PaymentFormCol>
                        </PaymentFormRow>
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </CheckoutCard>
              </form>
            )
          }}
        />
      )}
    </PaymentFormContainer>
  )
}

export default CheckoutPayment
