export const SET_TRAVELLER_VALID = 'SET_TRAVELLER_VALID'
export const SET_EDITED = 'SET_EDITED'
export const SET_PAYMENT_INFO = 'SET_PAYMENT_INFO'

export const setTravellerValid = (index: number, valid: boolean): any => ({
  type: SET_TRAVELLER_VALID,
  index,
  valid,
})

export const setTravellersEdited = (index: number, edited: boolean): any => ({
  type: SET_EDITED,
  index,
  edited,
})

export type PaymentInfo = {
  paymentAmount?: string
  paymentMethod?: string
  creditcardIssuer?: string
  termsConditions?: string
  creditcardMaximum?: number
}

export const setPaymentInfo = (newPaymentInfo: PaymentInfo): any => ({
  type: SET_PAYMENT_INFO,
  newPaymentInfo,
})
