import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import hexToRgba from 'hex-to-rgba'
import { useLocation, useParams, Link } from 'react-router-dom'
import { Container, Row, Col, Hidden, Visible, useScreenClass } from 'react-grid-system'
import styled from 'styled-components'

import { GuideType, QuoteDataType } from 'types'

import { ApplicationState } from 'reducers'

import getMenuItems from 'helpers/menuItems'

import useCompany from 'hooks/useCompany'

import { BKR, MOR, OTC, SLR } from 'styles/Logo'
import { H1 } from 'styles/Heading'
import Box from 'styles/Box'
import Button from 'styles/Button'
import { ArrowRightIcon } from 'styles/Icon'

import TravelGuide from 'components/TravelGuide'

import trustPilot from 'assets/trustpilot-logo.svg'
import stars from 'assets/stars.svg'
import sgr from 'assets/images/logo-sgr-white.svg'
import sgrz from 'assets/images/logo-sgrz-white-v2.svg'
import cf from 'assets/images/logo-cf-white.svg'
import anvr from 'assets/images/logo_anvr_wit.svg'

import travelifeLogoWhite from 'assets/images/logo-travelife-white.png'
import beeldmerk from 'assets/images/beeldmerk.jpg'
import TRL from 'styles/Logo/logos/TRL'
import TravelifeIcon from 'styles/Icon/icons/Travelife'

const FooterStyle = styled.div<any>`
  background-color: ${({ theme, env }) => {
    if (env === 'otc') {
      return theme.color.neutral.darkest
    }
    if (env === 'slr') {
      return theme.color.primary.base
    }
    return theme.color.neutral.darker
  }};
  font-size: 1rem;
  color: white;

  & > p {
    color: ${({ theme }) => theme.color.neutral.lighter};
  }
`

const FooterTop = styled.div<any>`
  height: 1000px;
  overflow: hidden;
  background: ${({ theme, image, env }) => {
    let gradientColor = theme.color.neutral.darker
    if (env === 'otc') {
      gradientColor = theme.color.neutral.darkest
    }
    if (env === 'slr') {
      gradientColor = theme.color.primary.base
    }

    return `linear-gradient(0deg, 
      ${hexToRgba(gradientColor)} 0%, 
      ${hexToRgba(gradientColor, 0.4)} 15%,
      ${hexToRgba(gradientColor, 0)} 30%),
      url('${image}')`
  }};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

const FooterLogos = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 1rem;
`

const FooterBottom = styled.div<{ $showBorder?: boolean }>`
  border-top: thin solid ${({ theme, $showBorder }) => ($showBorder ? theme.color.neutral.lighter : 'transparent')};
  width: 100%;
  margin: 0 auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`

const SubText = styled.p`
  max-width: 100%;
  width: 600px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.color.neutral.lighter};

  a {
    color: ${({ theme }) => theme.color.neutral.lighter};
    font-style: italic;
  }
`

const Title = styled(H1)`
  text-align: center;
  color: #fff;
`

const TitleBold = styled.span`
  font-weight: 600;
  color: #fff;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
`

const FooterLinks = styled.div`
  margin-top: 0.8rem;
  text-align: right;
`

const FooterLink = styled.a`
  color: ${({ theme }) => theme.color.neutral.lighter};
  text-decoration: none;
  margin-left: 1rem;
  white-space: nowrap;

  & + a::before {
    margin-right: 1rem;
    height: 8px;
    width: 1px;
    content: '';
    border-right: thin solid ${({ theme }) => theme.color.neutral.lighter};
  }

  :hover {
    color: white;
    opacity: 0.7;
  }

  &:first-child {
    margin-left: 0;
  }
`

const TrustPilotLogo = styled.img`
  height: 2rem;
  margin-right: 2rem;
  margin-bottom: 0.2rem;
`

const TrustPilotStars = styled.img`
  height: 2rem;
  margin-top: 0.5rem;
`

const LogoLink = styled.a`
  padding: 2rem;
  display: block;
  margin: 0 auto;
  max-width: 300px;
  display: flex;
  justify-content: flex-start;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 769px) {
    padding: 1rem;
  }
`

const FooterTitle = ({ daysLeft, status, email, hideDates }: any) => {
  if (hideDates) {
    return <></>
  }

  if (status === 'booked') {
    return (
      <>
        <Title>Uw reis is geboekt!</Title>
        <SubText>In deze online omgeving vindt u alle gegevens over uw geboekte reis.</SubText>
      </>
    )
  }
  if (status === 'lost' || status === 'expired') {
    return (
      <>
        <Title>Deze offerte is verlopen</Title>
        <SubText>
          <span>
            Wilt u deze reis toch boeken? Dat is uiteraard mogelijk, we moeten alleen de beschikbaarheid en tarieven
            opnieuw voor u bekijken. Neem contact op met uw reisadviseur via
          </span>
          <Box m={0} ml="0.3rem" inline>
            <a href={`mailto:${email}`}>{email}</a>
          </Box>
          <span>.</span>
        </SubText>
      </>
    )
  }
  if (daysLeft === 0) {
    return (
      <>
        <Title>
          Deze offerte is alleen <TitleBold>vandaag</TitleBold> nog geldig
        </Title>
        <SubText>
          Deze reis hebben we op maat voor u samengesteld. Vanwege afspraken met onze leveranciers kunnen wij de
          afgesproken prijzen en boektijden voor een beperkte periode voor u vasthouden.
        </SubText>
      </>
    )
  }

  if (daysLeft > 0) {
    return (
      <>
        <Title>
          Deze offerte is na vandaag nog
          <TitleBold>
            {daysLeft} {daysLeft === 1 ? 'dag' : 'dagen'}
          </TitleBold>
          geldig
        </Title>
        <SubText>
          Deze reis hebben we op maat voor u samengesteld. Vanwege afspraken met onze leveranciers kunnen wij de
          afgesproken prijzen en boektijden voor een beperkte periode voor u vasthouden.
        </SubText>
      </>
    )
  }

  return (
    <>
      <Title>Deze offerte is verlopen</Title>
      <SubText>
        <span>
          Wilt u deze reis toch boeken? Dat is uiteraard mogelijk, we moeten alleen de beschikbaarheid en tarieven
          opnieuw voor u bekijken. Neem contact op met uw reisadviseur via
        </span>
        <Box m={0} ml="0.3rem" inline>
          <a href={`mailto:${email}`}>{email}</a>
        </Box>
        <span>.</span>
      </SubText>
    </>
  )
}

const Footer: React.FC = () => {
  const { trustPilotLink, short, company, co2Link, disclaimerLink, privacyLink, TACLink } = useCompany()
  const { pathname } = useLocation()
  const { itineraryId }: any = useParams()

  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const screenClass = useScreenClass()
  const largeScreen = ['xl', 'lg'].includes(screenClass)

  const showTrustIcons = useMemo(() => short === 'mor' || short === 'slr' || short === 'trl' || short === 'bkr', [
    short,
  ])

  if (!itinerary) {
    return null
  }

  const daysLeft = moment(itinerary.expireDate).startOf('day').diff(moment(Date.now()).startOf('day'), 'days', true)

  const menuItems = getMenuItems(
    itinerary?.status === 'booked',
    itinerary?.isZRA,
    itinerary?.adjustTravellers,
    itinerary?.hidePrices,
    itinerary?.hideDates,
    itinerary?.isExcursion
  ).filter((menuItem) => menuItem.display)

  const currentPageIndex: number = menuItems.findIndex((item) => pathname.includes(item.link))
  const nextPage = menuItems[currentPageIndex + 1]

  const zra: GuideType = {
    image: itinerary.zra.ZRAProfilePicture || itinerary.zra.logoUrl || itinerary.guide.image,
    description: '',
    ...itinerary.zra,
  }
  const guide = itinerary.isZRA ? zra : itinerary.guide

  const getURLS = () => {
    switch (short) {
      case 'mor':
        return {
          generalURL: 'https://www.middenoostenreizen.com/vertrouwd-en-veilig/',
        }
      case 'slr':
        return {
          generalURL: 'https://www.srilankareizen.com/vertrouwd-en-veilig/',
        }
      case 'trl':
        return {
          generalURL: 'https://www.travellegends.nl/vertrouwd-en-veilig/',
        }
      case 'bkr':
        return {
          generalURL: 'https://www.balkanreizen.com/vertrouwd-en-veilig/',
        }
      default:
        return {
          generalURL: 'https://www.middenoostenreizen.com/vertrouwd-en-veilig/',
        }
    }
  }

  const { generalURL } = getURLS()

  return (
    <>
      {itinerary.guide && <TravelGuide guide={guide} />}
      {itinerary.footerImage && <FooterTop image={itinerary.footerImage} env={short} />}
      <FooterStyle env={short}>
        <Container>
          <Box m={0} pt="20rem" pb="15rem" align="center">
            <FooterTitle
              daysLeft={daysLeft}
              status={itinerary?.status}
              email={itinerary.isZRA ? itinerary.zra.email : itinerary.guide.email}
              hideDates={itinerary?.hideDates}
            />

            {nextPage && (
              <Link to={`/${itineraryId}/${menuItems[currentPageIndex + 1].link}`}>
                <Button type="neutral" variant="lightest" afterIcon={<ArrowRightIcon size="1rem" />} outline>
                  {menuItems[currentPageIndex + 1].name}
                </Button>
              </Link>
            )}
          </Box>

          {!itinerary.isZRA && (
            <FooterLogos>
              <Row style={{ justifyContent: 'center' }}>
                {short !== 'trl' && (
                  <Col xxl={3} xl={4} lg={6} md={12}>
                    <LogoLink href="https://www.travellegends.nl" target="_blank" rel="noopener noreferrer">
                      <TRL width="100%" />
                    </LogoLink>
                  </Col>
                )}
                {short !== 'mor' && (
                  <Col xxl={3} xl={4} lg={6} md={12}>
                    <LogoLink href="https://www.middenoostenreizen.com" target="_blank" rel="noopener noreferrer">
                      <MOR width="100%" />
                    </LogoLink>
                  </Col>
                )}
                {short !== 'slr' && (
                  <Col xxl={3} xl={4} lg={6} md={12}>
                    <LogoLink href="https://www.srilankareizen.com" target="_blank" rel="noopener noreferrer">
                      <SLR width="100%" />
                    </LogoLink>
                  </Col>
                )}
                {short !== 'bkr' && (
                  <Col xxl={3} xl={4} lg={6} md={12}>
                    <LogoLink href="https://www.balkanreizen.com" target="_blank" rel="noopener noreferrer">
                      <BKR width="100%" />
                    </LogoLink>
                  </Col>
                )}
              </Row>
            </FooterLogos>
          )}

          <FooterBottom $showBorder={showTrustIcons}>
            <Row>
              <Col sm={12} md={6} xl={6} xxl={4}>
                {!itinerary.isZRA && (
                  <a
                    href={`https://nl.trustpilot.com/review/${trustPilotLink}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box m={0} align="left">
                      <TrustPilotLogo src={trustPilot} alt="trust-pilot-logo" />
                      <TrustPilotStars src={stars} alt="trust-pilot-stars" />
                    </Box>
                  </a>
                )}
              </Col>
              {showTrustIcons && (
                <>
                  <Hidden xs sm md>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={4}>
                      <Box m={0} align="right" vAlign="center" style={{ display: largeScreen ? 'block' : 'flex' }}>
                        <Box m={0} mr="1.5rem" inline>
                          <a href={generalURL} target="_blank" rel="noopener noreferrer">
                            <img src={anvr} alt="anvr" width="auto" height="46px" />
                          </a>
                        </Box>
                        <Box m={0} mr="1.5rem" inline>
                          <a href={generalURL} target="_blank" rel="noopener noreferrer">
                            <img src={sgr} alt="sgr" />
                          </a>
                        </Box>
                        <Box m={0} mr="1.5rem" inline>
                          <a href={generalURL} target="_blank" rel="noopener noreferrer">
                            <img src={sgrz} alt="sgrz" width="auto" height="46px" />
                          </a>
                        </Box>
                        <Box m={0} mr="1.5rem" inline>
                          <a href={generalURL} target="_blank" rel="noopener noreferrer">
                            <img src={cf} alt="cf" />
                          </a>
                        </Box>
                        <Box m={0} inline>
                          <a href={co2Link} target="_blank" rel="noopener noreferrer">
                            <TravelifeIcon></TravelifeIcon>
                          </a>
                        </Box>
                      </Box>
                    </Col>
                  </Hidden>
                  <Hidden lg xl xxl>
                    <Col sm={12} md={12}>
                      <Box m={0} mt="1rem" inline vAlign="center" align="right" style={{ overflow: 'hidden' }}>
                        <Box m={0} mr="0.5rem" inline>
                          <a href={generalURL} target="_blank" rel="noopener noreferrer">
                            <img src={anvr} alt="anvr" width="auto" height="46px" />
                          </a>
                        </Box>
                        <Box m={0} mr="0.5rem" inline>
                          <a href={generalURL} target="_blank" rel="noopener noreferrer">
                            <img src={sgr} alt="sgr" />
                          </a>
                        </Box>
                        <Box m={0} mr="0.5rem" inline>
                          <a href={generalURL} target="_blank" rel="noopener noreferrer">
                            <img src={sgrz} alt="sgrz" width="auto" height="46px" />
                          </a>
                        </Box>
                        <Box m={0} mr="0.5rem" inline>
                          <a href={generalURL} target="_blank" rel="noopener noreferrer">
                            <img src={cf} alt="cf" />
                          </a>
                        </Box>
                        <Box m={0} inline>
                          <a href={co2Link} target="_blank" rel="noopener noreferrer">
                            <TravelifeIcon></TravelifeIcon>
                          </a>
                        </Box>
                      </Box>
                    </Col>
                  </Hidden>
                </>
              )}
              {!showTrustIcons && company !== 'Travelwizzard.nl' && (
                <Col xl={3} md={6}>
                  <Hidden xs sm>
                    <Box m={0} mt="0.4rem" inline>
                      <a href={co2Link} target="_blank" rel="noopener noreferrer">
                        <TravelifeIcon></TravelifeIcon>
                      </a>
                    </Box>
                  </Hidden>
                  <Hidden md lg xl xxl>
                    <Box m={0} mt="1rem" inline>
                      <a href={co2Link} target="_blank" rel="noopener noreferrer">
                        <TravelifeIcon></TravelifeIcon>
                      </a>
                    </Box>
                  </Hidden>
                </Col>
              )}
              <Col sm={12} xl={4} xxl={4}>
                {!itinerary.isZRA && (
                  <FooterLinks>
                    <Visible xl>
                      <Box m={0} align="left">
                        <FooterLink href={disclaimerLink} target="_blank">
                          Disclaimer
                        </FooterLink>
                        <FooterLink href={TACLink} target="_blank">
                          Algemene voorwaarden
                        </FooterLink>
                        <FooterLink href={privacyLink} target="_blank">
                          Privacy
                        </FooterLink>
                      </Box>
                    </Visible>
                    <Visible xxl>
                      <Box m={0} align="right">
                        <FooterLink href={disclaimerLink} target="_blank">
                          Disclaimer
                        </FooterLink>
                        <FooterLink href={TACLink} target="_blank">
                          Algemene voorwaarden
                        </FooterLink>
                        <FooterLink href={privacyLink} target="_blank">
                          Privacy
                        </FooterLink>
                      </Box>
                    </Visible>
                    <Visible sm md lg>
                      <Box m={0} mt="2rem" align="left">
                        <FooterLink href={disclaimerLink} target="_blank">
                          Disclaimer
                        </FooterLink>
                        <FooterLink href={TACLink} target="_blank">
                          Algemene voorwaarden
                        </FooterLink>
                        <FooterLink href={privacyLink} target="_blank">
                          Privacy
                        </FooterLink>
                      </Box>
                    </Visible>
                    <Visible xs>
                      <Box m={0} mt="2rem" align="left">
                        <div>
                          <FooterLink href={disclaimerLink} target="_blank">
                            - Disclaimer
                          </FooterLink>
                        </div>
                        <div>
                          <FooterLink href={TACLink} target="_blank">
                            - Algemene voorwaarden
                          </FooterLink>
                        </div>
                        <div>
                          <FooterLink href={privacyLink} target="_blank">
                            - Privacy
                          </FooterLink>
                        </div>
                      </Box>
                    </Visible>
                  </FooterLinks>
                )}
              </Col>
            </Row>
          </FooterBottom>
        </Container>
      </FooterStyle>
    </>
  )
}

export default Footer
